import { HttpErrorResponse, provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http'
import { ApplicationConfig, importProvidersFrom, isDevMode } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { provideAnimations } from '@angular/platform-browser/animations'
import { provideRouter, withRouterConfig } from '@angular/router'
import { provideServiceWorker } from '@angular/service-worker'
import '@stripe/stripe-js'
import { QueryClient, provideAngularQuery } from '@tanstack/angular-query-experimental'
import { provideCharts, withDefaultRegisterables } from 'ng2-charts'
import { IconsModule } from 'src/app/common/icons/icons.module'
import { translationsProviders, translocoProviders } from 'src/app/transloco.config'

import { AppConfig as Configurations } from '~core/config/config'
import { provideCore } from '~core/core.config'
import { acceptLanguageInterceptor } from '~core/interceptors/accept-language.interceptor'
import { jwtInterceptor } from '~core/interceptors/jwt.interceptor'
import { projectsConfig } from '~features/projects/projects.config'
import { reportsConfig } from '~features/reports/reports.config'

import { routes } from './app.routes'
import { provideMaterial } from './material.config'
import { provideAppStore } from './store.config'

export const appConfig: ApplicationConfig = {
  providers: [
    ...provideMaterial(),
    importProvidersFrom(BrowserModule, IconsModule),
    provideCharts(withDefaultRegisterables()),
    ...provideCore(),
    ...provideAppStore(),
    ...translocoProviders,
    ...translationsProviders,
    ...projectsConfig,
    ...reportsConfig,
    provideRouter(routes, withRouterConfig(Configurations.ROUTER)),
    provideHttpClient(withInterceptorsFromDi(), withInterceptors([acceptLanguageInterceptor, jwtInterceptor])),
    provideAngularQuery(
      new QueryClient({
        defaultOptions: {
          queries: {
            throwOnError: false,
            retry: (failureCount, error) => {
              // do not retry on 401 or 403
              if (error instanceof HttpErrorResponse && [401, 403].includes(error.status)) {
                return false
              }
              return failureCount < 3
            },
            refetchOnWindowFocus: false, // default: true
          },
        },
      }),
    ),
    provideAnimations(),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
}
